<template>
    <div class="page">
        <img src="../assets/image/xiaMenBG.png" class="chinaBG">
        <dv-loading class="pageLoading" v-if="loading">Loading...</dv-loading>
        <div class="header">
            <img class="bj" src="../assets/image/header.png">
            <div class="container">
                <div class="left">
                    <div :class="['icon_tq', 'tq-'+weather.wea_img]"></div>
                    <div class="des">
                        <div class="t">{{weather.wea}} {{weather.tem2}} ~ {{weather.tem1}}℃</div>
                        <div class="t1">cloudy</div>
                    </div>
                </div>
                <div class="middle">
                    <div class="t"  @click="$router.back()" style="cursor: pointer;">精神疾病用药管理治安大屏</div>
                    <div class="t1">PUBLIC SECURITY FOR DRUG ADMINISTRATION OF MENTAL DISEASES</div>
                </div>
                <div class="right">
                    <div class="des">
                        <div class="t">{{timeStr}}</div>
                        <div class="t1">{{timeStr1}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="body">
            <div class="leftContainer">
                <div class="cardList">
                    <div class="card">
                        <img src="../assets/image/card1.png" alt="">
                        <div class="t">近30天监管次数</div>
                        <div class="v">
                            <DigitalTransform :key="key" :value="stat_01.attendance_days" :interval="500"></DigitalTransform>
                            <sub>天</sub>
                        </div>
                        <dv-border-box-8 :dur="5" :color="['unset', '#1DC9B7']" :reverse="false" style="width:100%;height:100%;position: absolute;top:0;left:0"></dv-border-box-8>
                    </div>
                    <div class="card">
                        <img src="../assets/image/card2.png" alt="">
                        <div class="t">监管人员数</div>
                        <div class="v">
                            <DigitalTransform :key="key" :value="stat_01.attendance_count" :interval="500"></DigitalTransform>
                            <sub>人</sub>
                        </div>
                        <dv-border-box-8 :dur="5" :color="['unset', '#FFB822']" :reverse="false" style="width:100%;height:100%;position: absolute;top:0;left:0"></dv-border-box-8>
                    </div>
                    <div class="card">
                        <img src="../assets/image/card3.png" alt="">
                        <div class="t">被监管人数</div>
                        <div class="v">
                            <DigitalTransform :key="key" :value="stat_01.ob_count" :interval="500"></DigitalTransform>
                            <sub>人</sub>
                        </div>
                        <dv-border-box-8 :dur="5" :color="['unset', '#D74CFF']" :reverse="false" style="width:100%;height:100%;position: absolute;top:0;left:0"></dv-border-box-8>
                    </div>
                    <div class="card">
                        <dv-border-box-8 :dur="5" :color="['unset', '#ECE156']" :reverse="false" style="width:100%;height:100%;position: absolute;top:0;left:0"></dv-border-box-8>

                        <img src="../assets/image/card4.png" alt="">
                        <div class="t">设备数</div>
                        <div class="v">
                            <DigitalTransform :key="key" :value="stat_01.devices_count" :interval="500"></DigitalTransform>
                            <sub>台</sub>
                        </div>
                    </div>
                </div>
                <div class="mapContainer">
                    <div id="chart" ref="chart"></div>
                </div>
            </div>
            <div class="middleContainer">
                <div class="cardContainer">
                    <div class="title">派出所监管统计（30日）</div>
                    <div class="content">
                        <dv-scroll-board :key="key" v-if="config.data.length>0" :config="config" />
                    </div>
                </div>
                <div class="cardContainer">
                    <div class="title">服药信息趋势图</div>
                    <div class="content">
                        <div id="chart" ref="chart2"></div>
                    </div>
                </div>

            </div>
            <div class="rightContainer">
                <div class="cardContainer">
                    <div class="title">7日处理统计</div>
                    <div class="content">
                        <div id="chart" ref="chart1"></div>
                    </div>
                </div>
                <div class="cardContainer">
                    <div class="title">激活设备趋势图</div>
                    <div class="content">
                        <div id="chart" ref="chart3"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import worldData from "./json/xiaMen.json";
    echarts.registerMap("xiaMen", worldData);
    import axios from '@/axios/index'
    import DigitalTransform from "vue-digital-transform";
    import * as moment from 'moment'
    import ut from '@/comm/ut'
    moment.locale("zh-cn")
    export default {
        components: {
            DigitalTransform
        },
        data() {
            return {
                actShowLableIndex: 0,
                weather: {},
                timeStr: '',
                timeStr1: '', //星期几
                key: new Date().getTime(),
                loading: true,
                visualMapMax: 0,
                stat_01: {
                    "attendance_days": 0,
                    "attendance_count": 0,
                    "ob_count": 0,
                    "devices_count": 0
                },
                stat_02: {},
                stat_03: [],
                stat_04: [],
                stat_05: [],
                stat_06: [],
                refreshTimer: null,
                config: {
                    headerBGC: "rgba(29, 201, 183, 0.1)",
                    oddRowBGC: "rgba(29, 201, 183, 0)", //奇数行背景色	
                    evenRowBGC: "rgba(29, 201, 183, 0.05)", //偶数行背景色	
                    rowNum: 7,
                    header: ['区域名称', '使用人数', '设备总数', '服药正常', '服药异常','异常处理'],
                    data: [],
                    // oddRowBGC: "transparent",
                    // evenRowBGC: " transparent;",
                    // hoverPause: true,
                    // waitTime: 1000,
                    columnWidth: [90],
                    align: ["left", "center", "center", "center", "center", "center"]
                }
            }
        },
        watch: {
            '$store.state.onresizeNumber': {
                handler() {
                    this.key = new Date().getTime()
                    if (this.chart) this.chart.resize();
                    if (this.chart1) {
                        this.chart1.clear()
                        this.setChart1()
                        this.chart1.resize();
                    }
                    if (this.chart2) {
                        this.chart2.clear()
                        this.setChart2()
                        this.chart2.resize()
                    }
                    if (this.chart3) {
                        this.chart3.clear()
                        this.setChart3()
                        this.chart3.resize()
                    }
                }
            }
        },
        async created() {
            this.getWeather()
            this.setTime()
            await this.getData()
            this.loading = false
        },
        methods: {
            async getData() { //请求数据 设置数据
                let response = await axios.request({
                    url: "/api/dataworks.org/xiamen_110",
                    method: "GET",
                })
                // stat_01: "统计数据"
                // stat_02: "地图数据"
                // stat_03: "街道监管统计"
                // stat_04: "7日警情统计"
                // stat_05: "服药信息趋势图"
                // stat_06: "激活设备趋势图"
                let { stat_01 = {}, stat_02 = [], stat_03 = [], stat_04 = [], stat_05 = [], stat_06 = [], refresh = 300 } = response.data.data
                if (this.refreshTimer) clearInterval(this.refreshTimer)
                this.refreshTimer = setInterval(this.getData, refresh * 1000);
                let visualMapMax = 0
                stat_02.forEach(item => {
                    item.value = parseFloat(item.value)
                    item.gps = [parseFloat(item.gps[0]), parseFloat(item.gps[1])]
                    if (item.value > visualMapMax) visualMapMax = item.value
                })
                this.visualMapMax = visualMapMax
                // stat_05.sort((item, item1) => item.month - item1.month)
                // stat_06.sort((item, item1) => item.month - item1.month)
                this.stat_01 = stat_01
                this.stat_02 = stat_02
                this.stat_03 = stat_03
                this.stat_04 = stat_04
                this.stat_05 = stat_05
                this.stat_06 = stat_06
                this.setChart()
                this.setChart1()
                this.setChart2()
                this.setChart3()
                this.setConfigData()
            },
            async getWeather() {
                let response = await axios.request({
                    url: "/api/common/weather",
                    method: "POST",
                    data: {
                        city: "厦门"
                    }
                })
                this.weather = response.data
            },
            setConfigData() { //设置街道监管统计数据
                let configData = []
                for (let i = 0; i < this.stat_03.length; i++) {
                    const item = this.stat_03[i];
                    let errDiv = item.errTaking > 0 ? `<div class="warning"><div class="dian"></div><div>${item.errTaking}</div></div>` : item.errTaking
                    configData.push([item.street, item.userAmt, item.deviceAmt, item.normalTaking, errDiv,item.errHandled])
                    // configData.push([item.street, item.userAmt, item.deviceAmt, item.normalTaking, errDiv])
                }
                this.config.data = configData
            },
            setTime() { //设置时间
                moment.locale("zh-cn")
                this.timeStr = `${moment().format('LTS')}　${ moment().format('LL')}`
                moment.locale("en")
                this.timeStr1 = `${ moment().format('LL')}`
                if (setIntervalTime) clearInterval(setIntervalTime)
                let setIntervalTime = setInterval(() => {
                    moment.locale("zh-cn")
                    this.timeStr = `${moment().format('LTS')}　${ moment().format('LL')}`
                    moment.locale("en")
                    this.timeStr1 = `${ moment().format('LL')}`
                }, 1000);
            },
            setChart() { //设置地图数据
                let stat_02 = this.stat_02
                let data = []
                for (let i = 0; i < stat_02.length; i++) {
                    const item = stat_02[i];
                    data.push({
                        label: {
                            show: i === 0 ? true : false
                        },
                        name: item.street,
                        value: [].concat(item.gps).concat(item.value)
                    })
                }
                this.chart = echarts.init(this.$refs.chart);
                let series = [
                    { //热力点
                        zlevel: 10,
                        type: 'heatmap',
                        coordinateSystem: 'geo',
                        data
                },
                    {
                        zlevel: 99,
                        type: 'effectScatter',
                        coordinateSystem: 'geo', //
                        rippleEffect: {
                            brushType: 'stroke'
                        },
                        symbolSize: 5,
                        itemStyle: {
                            normal: {
                                opacity: 1,
                                color: 'rgba(49, 255, 233, 1)'
                            }
                        },
                        data,
                        // tooltip: {
                        //     borderColor: "",
                        //     borderWidth: 0,
                        //     backgroundColor: "",
                        //     padding: 0,
                        //     formatter: function(params, ticket, callback) {
                        //         if (params.seriesType == "effectScatter") {
                        //             return `<div style="border: 1px solid #C216F4;height:32px;text-align: center;display:flex;font-size: 16px;color:#FFFFFF;font-weight: 600;width:auto">
                        //                     <div style="background: rgba(194, 22, 244, 0.4);line-height: 32px;padding:0 17px;border-right: 1px solid #C216F4;">${params.name}</div>
                        //                     <div style="line-height: 32px;padding:0 17px;background: rgba(194, 22, 244, 0.3);">${params.value[params.value.length-1]}</div>
                        //                 </div>`
                        //         }
                        //     }
                        // }
                        label: {
                            show: true,
                            color: "#FFFFFF",
                            opacity: 1,
                            position: "right",
                            offset: [0, 0],
                            formatter: function(params) {
                                return `{line|——}{name|${params.data.name}}{value|${params.data.value[params.data.value.length-1]}}`
                                // return `{name|${params.name}{value|{d}%}`,
                            },
                            rich: {
                                line: {
                                    color: "#FFFFFF"
                                },
                                name: {
                                    backgroundColor: 'rgba(194, 22, 244, 0.6)',
                                    lineHeight: 32,
                                    padding: [6, 17, 5, 17],
                                    borderColor: "#C216F4",
                                    borderWidth: 0.5,
                                    fontSize: 16,
                                    color: "#FFFFFF"
                                },
                                value: {
                                    backgroundColor: 'rgba(194, 22, 244,0.3)',
                                    lineHeight: 32,
                                    padding: [6, 17, 5, 17],
                                    borderColor: "#C216F4",
                                    borderWidth: 0.5,
                                    fontSize: 16,
                                    color: "#FFFFFF"
                                }
                            }
                        }
            }]
                this.chart.setOption({
                    visualMap: {
                        show: false,
                        orient: "horizontal",
                        zlevel: 999,
                        type: "continuous", //类型为分段型
                        seriesIndex: 0, //指定取哪个系列的数据，即哪个系列的  默认取所有系列 不指定会覆盖所有颜色
                        min: 0,
                        max: 300,
                        // splitNumber: 5, //分5段
                        inRange: {
                            color: ['rgba(49, 255, 233, 1)'] //每段的颜色
                        }
                    },
                    tooltip: {
                        show: true
                    },
                    grid: {
                        width: '100%',
                        height: '100%',
                        // left: '0%',
                        // right: '0%',
                        // bottom: '0%',
                        // top:'10%',
                        containLabel: true
                    },
                    // layoutCenter: ['70%', '50%'], //位置
                    // layoutSize: '65%', //大小
                    geo: {
                        tooltip: {
                            trigger: 'item',
                            formatter: function(params, ticket, callback) { return }
                        },
                        name: '世界地图',
                        type: 'map',
                        map: 'xiaMen',
                        label: {
                            show: false,
                            color: '#fff',
                            emphasis: {
                                show: true,
                                color: '#fff'
                            }
                        },
                        zoom: 1.2,
                        roam: false, //支持拖拽缩放
                        // scaleLimit: { //滚轮缩放的极限控制
                        //     min: 0.5, //缩放最小大小
                        //     max: 6, //缩放最大大小
                        // },
                        itemStyle: {
                            normal: {
                                borderWidth: 1, //边际线大小
                                areaColor: '#3d6fd9', //默认区域颜色
                                borderColor: '#719dfa'
                            },
                            emphasis: {
                                show: true,
                                // areaColor: '#2a333d'
                                areaColor: '#3066ba', //鼠标滑过区域颜色
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#fff'
                                    }
                                }
                            }
                        }
                    },
                    series
                })
                let options = this.chart.getOption();
                if (this.mapLableShowTimer) clearInterval(this.mapLableShowTimer)
                this.mapLableShowTimer = setInterval(() => {
                    this.actShowLableIndex = this.actShowLableIndex === options.series[1].data.length - 1 ? 0 : this.actShowLableIndex + 1
                    options.series[1].data.forEach((item, index) => {
                        item.label.show = index === this.actShowLableIndex ? true : false
                    })
                    this.chart.setOption(options, true);
                }, 3000);
                // this.chart.dispatchAction({
                //     type: 'showTip',
                //     seriesIndex: 1,
                //     dataIndex: 0,
                // })
            },
            mapLableShow() { //地图点的label 轮显

            },
            setChart1() { //7日警情统计
                this.chart1 = echarts.init(this.$refs.chart1);
                let abnmlData = []
                let capOutData = []
                let dateData = []
                for (let i = 0; i < this.stat_04.length; i++) {
                    const item = this.stat_04[i];
                    abnmlData.push(item.abnml)
                    capOutData.push(item.capOut)
                    // let YYYY = moment(item.date).format('YYYY')
                    let MMDD = moment(item.date).format('MM/DD')
                    // dateData.push(`${YYYY}\n${MMDD}`)
                    dateData.push(MMDD)
                }

                let series = [{
                        name: '异常数',
                        type: 'bar',
                        barGap: '20%',
                        emphasis: {
                            focus: 'series'
                        },
                        itemStyle: {
                            borderRadius: [5, 5, 0, 0]
                        },
                        data: abnmlData
                    },
                    {
                        name: '处理数',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        itemStyle: {
                            borderRadius: [5, 5, 0, 0]
                        },
                        data: capOutData
                    }
                ]
                this.chart1.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    color: ["rgba(194, 22, 244, 0.8)", "rgba(29, 201, 183, 0.8)"],
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '0',
                        containLabel: true
                    },
                    legend: {
                        textStyle: {
                            fontSize: ut.fontSize(14),
                            color: 'rgba(255, 255, 255, 0.9)'
                        },
                        data: ['异常数', '处理数']
                    },
                    xAxis: [{
                        type: 'category',
                        axisTick: { show: false },
                        data: dateData,
                        axisLabel: {
                            fontSize: ut.fontSize(11.5),
                            color: "rgba(255, 255, 255, 0.6)"
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(255, 255, 255, 0.2)"
                            }
                        },

                    }],
                    yAxis: [{
                        axisLabel: {
                            show: false,
                        },
                        splitLine: {
                            lineStyle: {
                                color: "rgba(255, 255, 255, 0.08)"
                            }
                        },
                        type: 'value'
                    }],
                    series
                })
            },
            setChart2() { //服药信息趋势图
                this.chart2 = echarts.init(this.$refs.chart2);
                let takerAmtData = [] // 服药人数
                let takingTimesData = [] // 服药次数
                let onTimeTakingData = [] // 按时服药次数 
                let abnmlTakingData = [] // 超时漏服药次数
                let dateData = []
                for (let i = 0; i < this.stat_05.length; i++) {
                    const item = this.stat_05[i];
                    abnmlTakingData.push(item.abnmlTaking)
                    onTimeTakingData.push(item.onTimeTaking)
                    takerAmtData.push(item.takerAmt)
                    takingTimesData.push(item.takingTimes)
                    dateData.push(`${item.year}\n${item.month}月`)
                }
                let series = [{
                        name: '服药人数',
                        type: 'line',
                        smooth: true,
                        emphasis: {
                            focus: 'series'
                        },
                        symbol: "circle",
                        symbolSize: ut.fontSize(8),
                        lineStyle: {
                            width: ut.fontSize(3)
                        },
                        data: takerAmtData
                    },
                    {
                        smooth: true,
                        name: '服药次数',
                        type: 'line',
                        emphasis: {
                            focus: 'series'
                        },
                        symbol: "circle",
                        symbolSize: ut.fontSize(8),
                        lineStyle: {
                            width: ut.fontSize(3)
                        },
                        data: takingTimesData
                },
                    {
                        smooth: true,
                        name: '按时服药次数',
                        type: 'line',
                        emphasis: {
                            focus: 'series'
                        },
                        symbol: "circle",
                        symbolSize: ut.fontSize(8),
                        lineStyle: {
                            width: ut.fontSize(3)
                        },
                        data: onTimeTakingData
                },
                    {
                        smooth: true,
                        name: '漏服药次数',
                        type: 'line',
                        emphasis: {
                            focus: 'series'
                        },
                        symbol: "circle",
                        symbolSize: ut.fontSize(8),
                        lineStyle: {
                            width: ut.fontSize(3)
                        },
                        data: abnmlTakingData
                }


                ]
                this.chart2.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    color: ["rgba(194, 22, 244, 0.8)", 'rgba(30, 143, 255, 0.8)', "rgba(29, 201, 183, 0.8)", 'rgba(255, 184, 34, 0.8)'],
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '0',
                        containLabel: true
                    },
                    legend: {
                        textStyle: {
                            fontSize: ut.fontSize(14),
                            color: 'rgba(255, 255, 255, 0.9)'
                        },
                        itemGap: ut.fontSize(5),
                        padding: [5, 0, 0, 0],
                        data: ['服药人数', '服药次数', '按时服药次数', '漏服药次数']
                    },
                    xAxis: [{
                        type: 'category',
                        axisTick: { show: false },
                        data: dateData,
                        axisLabel: {
                            color: "rgba(255, 255, 255, 0.6)",
                            fontSize: ut.fontSize(11),
                        },

                        axisLine: {
                            lineStyle: {
                                color: "rgba(255, 255, 255, 0.2)"
                            }
                        }
                    }],
                    yAxis: [{
                        axisLabel: {
                            show: false,
                        },
                        splitLine: {
                            lineStyle: {
                                color: "rgba(255, 255, 255, 0.08)"
                            }
                        },
                        type: 'value'
                    }],
                    series
                })
            },
            setChart3() { //激活设备趋势图
                this.chart3 = echarts.init(this.$refs.chart3);
                let UserAmtData = [] // 用户数
                let actvUserAmtData = [] // 活跃用户数 
                let rgstDeviceAmtData = [] // 激活设备数
                let dateData = []
                for (let i = 0; i < this.stat_06.length; i++) {
                    const item = this.stat_06[i];
                    UserAmtData.push(item.UserAmt)
                    actvUserAmtData.push(item.actvUserAmt)
                    rgstDeviceAmtData.push(item.rgstDeviceAmt)
                    dateData.push(`${item.year}\n${item.month}月`)
                }
                let series = [{
                        name: '用户数',
                        type: 'line',
                        smooth: true,
                        emphasis: {
                            focus: 'series'
                        },
                        symbol: "circle",
                        symbolSize: ut.fontSize(8),
                        lineStyle: {
                            width: ut.fontSize(3)
                        },
                        data: UserAmtData
                    },
                    {
                        smooth: true,
                        name: '活跃用户数',
                        type: 'line',
                        emphasis: {
                            focus: 'series'
                        },
                        symbol: "circle",
                        symbolSize: ut.fontSize(8),
                        lineStyle: {
                            width: ut.fontSize(3)
                        },
                        data: actvUserAmtData
                    },
                    {
                        smooth: true,
                        name: '激活设备数',
                        type: 'line',
                        emphasis: {
                            focus: 'series'
                        },
                        symbol: "circle",
                        symbolSize: ut.fontSize(8),
                        lineStyle: {
                            width: ut.fontSize(3)
                        },
                        data: rgstDeviceAmtData
                }

                ]
                this.chart3.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    color: ["rgba(194, 22, 244, 0.8)", "rgba(29, 201, 183, 0.8)", 'rgba(255, 184, 34, 0.8)'],
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '0',
                        containLabel: true
                    },
                    legend: {
                        textStyle: {
                            fontSize: ut.fontSize(14),
                            color: 'rgba(255, 255, 255, 0.9)'
                        },
                        itemGap: ut.fontSize(5),
                        padding: [5, 0, 0, 0],
                        data: ['用户数', '活跃用户数', '激活设备数']
                    },
                    xAxis: [{
                        type: 'category',
                        axisTick: { show: false },
                        data: dateData,
                        axisLabel: {
                            fontSize: ut.fontSize(11),
                            color: "rgba(255, 255, 255, 0.6)"
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(255, 255, 255, 0.2)"
                            }
                        }
                    }],
                    yAxis: [{
                        axisLabel: {
                            show: false,
                        },
                        splitLine: {
                            lineStyle: {
                                color: "rgba(255, 255, 255, 0.08)"
                            }
                        },
                        type: 'value'
                    }],
                    series
                })
            }
        },
        destroyed() {
            if (this.mapLableShowTimer) clearInterval(this.mapLableShowTimer)
            if (this.refreshTimer) clearInterval(this.refreshTimer)
            if (this.chart) this.chart.dispose()
            if (this.chart1) this.chart1.dispose()
            if (this.chart2) this.chart2.dispose()
            if (this.chart3) this.chart3.dispose()
        }
    }
</script>
<style lang="less" scoped>
    .pageLoading {
        background-color: #1330b8;
        color: #FFFFFF;
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 99;
        top: 0;
        left: 0;
    }


    .page {
        background-color: #1330b8;
        display: flex;
        flex-direction: column;
        position: relative;

        .chinaBG {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            vertical-align: top;
        }
    }

    #chart {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .header {
        width: 100%;
        position: relative;

        .bj {
            width: 100%;
            vertical-align: top;
        }

        .container {
            // border: 1px solid red;
            box-sizing: border-box;
            top: 0;
            left: 0;
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;

            .left {
                width: 5.15rem;
                height: 100%;
                // border: 1px solid red;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFFFFF;
                padding-top: .3rem;

                .icon_tq {
                    font-size: .52rem;
                    margin-right: .2rem;
                }

                .des {


                    .t {
                        font-size: .24rem;
                        font-weight: 400;
                        height: .34rem;
                    }

                    .t1 {
                        height: .23rem;
                        font-size: .16rem;
                        font-weight: 400;
                        opacity: 0.7;
                    }

                }


            }

            .middle {
                flex: auto;
                // border: 1px solid red;
                box-sizing: border-box;
                position: relative;

                .t {
                    position: absolute;
                    left: 0.9rem;
                    top: .1rem;
                    font-size: .6rem;
                    font-weight: 800;
                    color: #FFFFFF;
                }

                .t1 {
                    position: absolute;
                    bottom: .1rem;
                    left: 1.75rem;
                    font-size: .14rem;
                    font-weight: 400;
                    letter-spacing: 0.01rem;
                    color: #FFFFFF;
                }
            }

            .right {
                width: 5.15rem;
                height: 100%;
                // border: 1px solid red;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFFFFF;
                padding-top: .3rem;

                .des {
                    width: 2.88rem;
                    text-align: right;

                    .t {
                        font-size: .24rem;
                        font-weight: 400;
                        height: .34rem;
                    }

                    .t1 {
                        height: .23rem;
                        font-size: .16rem;
                        font-weight: 400;
                        opacity: 0.7;
                    }

                }



            }


        }
    }

    .body {
        margin-top: .34rem;
        flex: auto;
        position: relative;
        overflow: hidden;
        display: flex;
        padding-bottom: .2rem;

        .leftContainer {
            margin-left: .4rem;
            box-sizing: border-box;
            flex: auto;
            height: 100%;
            display: flex;
            flex-direction: column;

            .cardList {
                flex-shrink: 0;
                width: 100%;
                display: flex;
                justify-content: space-between;

                .card {
                    width: 2.13rem;
                    position: relative;
                    min-height: 1.4033rem;

                    img {
                        width: 100%;
                    }

                    .t {
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        color: #FFFFFF;
                        font-size: .2rem;
                        font-weight: 400;
                        top: .14rem;
                    }

                    .v {
                        position: absolute;
                        width: 100%;
                        top: .54rem;
                        text-align: center;
                        font-size: .5rem;
                        font-weight: 800;
                        color: #1DC9B7;

                        sub {
                            font-size: .2rem;
                            font-weight: 600;
                            margin-left: .2rem;
                            position: relative;
                            bottom: .12rem;
                        }
                    }

                    &:nth-child(2) {
                        .v {
                            color: #FFB822;
                        }

                    }

                    &:nth-child(3) {
                        .v {
                            color: #D74CFF;
                        }

                    }

                    &:nth-child(4) {
                        .v {
                            color: #ECE156;
                        }
                    }
                }
            }

            .mapContainer {
                flex: auto;
                width: 100%;
            }
        }

        .cardContainer {
            width: 100%;
            overflow: hidden;
            height: 49%;
            padding: .2rem;
            box-sizing: border-box;
            background: rgba(17, 44, 171, 0.8);
            display: flex;
            flex-direction: column;
            position: relative;

            .title {
                padding-left: .12rem;
                font-size: .28rem;
                font-weight: 800;
                color: #FFFFFF;
                position: relative;

                &::after {
                    content: "";
                    width: .04rem;
                    height: 70%;
                    left: 0;
                    top: 20%;
                    background: #1DC9B7;
                    position: absolute;
                }
            }

            .content {
                margin-top: .2rem;
                // border: 1px solid red;
                box-sizing: border-box;
                width: 100%;
                flex: auto;

                /deep/ .warning {
                    // border: 1px solid red;
                    box-sizing: border-box;
                    color: #F56C6C;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .dian {
                        background-color: #F56C6C;
                        border-radius: 50%;
                        width: 9px;
                        height: 9px;
                        margin-right: 6px;
                    }
                }
            }
        }

        .middleContainer {
            flex-shrink: 0;
            margin-left: .2rem;
            width:5.2rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            /deep/ .dv-scroll-board .header .header-item {
                font-size: .14rem;
                font-weight: 800;
            }

            /deep/ .dv-scroll-board .rows .row-item {
                font-weight: 400;
                font-size: .14rem;
            }

        }


        .rightContainer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: .2rem;
            margin-left: .2rem;
            width: 4.45rem;
            height: 100%;
        }
    }
</style>